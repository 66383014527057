import * as React from "react";
import {
  Segment,
  Container,
  Grid,
  Header,
  Icon
} from "semantic-ui-react";
import { TeamJson } from "../../graphql-types";
import Img from "gatsby-image";

const ProfileCard: React.SFC<TeamJson> = ({
  name,
  jobTitle,
  bio,
  skills,
  linkedIn,
  email,
  avatar,
  credentials
}) => {
  return (
    <Container style={{ paddingTop: 20 }} >
      <Segment  style={{ textAlign: "center"}}>
        <Header as={"h3"} style={{ marginBottom: 0 }}>
          {name},<span className="italics" style={{fontSize: 15, marginLeft: 5}}>{credentials}</span>
        </Header>
       
        <span className="italics">{jobTitle}</span> 
        <Grid container={true} stackable columns={2} verticalAlign="middle" centered={true}>
          <Grid.Row>
            <Grid.Column width={3}>
              <Img
                fluid={avatar.childImageSharp.fluid}
                style={{ borderRadius: 5, marginTop: 8, marginBottom: 5, boxShadow: "0px 0px 3px 0px #000A" }}
              />
              <Grid.Row >
                <Grid.Column style={{ textAlign: "center" }}>
                  <a href={`mailto:${email}`} target="_blank"><Icon link name="mail outline" style={{ paddingRight: 10 }} size="large" /></a>
                  <a href={linkedIn} target="_blank"><Icon link name="linkedin" size="large" /></a>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={13} className="paragraph-spaced">
              {(bio as string[]).map((paragraph,index) => <p key={index}>{paragraph}</p>)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Header as={"h4"}>{skills.join(" | ")}</Header>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}

export default ProfileCard; 