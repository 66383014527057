import * as React from "react";
import { withLayout, LayoutProps } from "../components/Layout";
import {
  Container,
  Header,
  Segment,
  Divider,
  Grid
} from "semantic-ui-react";
import { TeamJson } from "../graphql-types";
import { graphql } from 'gatsby';
import ProfileCard from "../components/ProfileCard";
import ValueCard from "../components/ValueCard";
import SEO from "../components/SEO";

interface AboutPageProps extends LayoutProps {
  data: {
    team: {
      members: TeamJson[]
    }
  }
}

const AboutPage: React.SFC<AboutPageProps> = ({
  data: {
    team: {
      members = []
    } = {}
  } = {},
  location: {
    pathname
  }
}) => {
  return (
    < >
      <SEO
        path={pathname}
        title="About Us"
        description="Meet the team at Rough Draft Developments."
      />

      <Segment vertical inverted padded="very">
        <Header inverted textAlign="center" as="h1" >
          About Rough Draft Developments
        </Header>
        <Container style={{ paddingBottom: 10, paddingTop: 20 }} className="paragraph-spaced-large">
          <p>Though we like to believe that, like all of us, we continue to grow and develop over time, Rough Draft was founded for a simple reason:</p>

          <p style={{ fontSize: 24 }}>We love exploring new ideas and we wanted to open ourselves up to new opportunities.</p>

          <p>Based out of Victoria, BC Canada, we are a team of two (yes, we are husband and wife), with a shared passion for technology and how it can enhance our lives.  Together, we have over 15 years of experience in the tech space - building native apps, web apps, and websites - and are comfortable with all stages of the software development life cycle.  Along the way, we have refined our process, so we can bring our best to your team. We love exploring new problem spaces and adapt quickly to unchartered territory. We are always interested in what others are working on and enjoy being part of new teams to help them bring their awesome ideas to reality.</p>
        </Container>
      </Segment>

      <Container style={{ paddingBottom: 10, paddingTop: 20 }}>
        <Divider horizontal style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Header as='h1' style={{ fontSize: "3rem" }} className="small-caps">
            Our core values
        </Header>
        </Divider>
        <Grid container={true} stackable columns={3} verticalAlign="middle" centered={true} style={{ paddingBottom: 40 }}>
          <Grid.Row>
            <Grid.Column>
              <ValueCard
                icon="refresh"
                title={"Value Driven Development"}
                text={"We test hypotheses, iterate often, and continually ask ourselves “is this work bringing value?”. We believe that failing fast allows us to create the best value for users."}
              />
            </Grid.Column>
            <Grid.Column>
              <ValueCard
                icon="handshake outline"
                title={"Collaboration"}
                text={"We know that the best solutions come from everyone having a voice. We collaborate with everyone on the team to develop creative solutions to difficult problems."} />
            </Grid.Column>

            <Grid.Column>
              <ValueCard
                icon="book"
                title={"Continuous Learning"}
                text={"We have worked hard to hone our skills, but we never hesitate to learn something new - whether it be from each other, the people we work with, our mistakes, or formal education."}
              />

            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ValueCard
                icon="idea"
                title={"Deep Work"}
                text={"Practicing Deep Work is a part of our everyday. We give ourselves the time to deeply engage with problems and space to come to creative solutions."}
              />
            </Grid.Column>
            <Grid.Column >
              <ValueCard
                icon="thumbs up outline"
                title={"Quality Work"}
                text={"Test, Review, Revise, Repeat. It’s how we approach the projects we take on - making them more scalable, maintainable, and robust."}
              />
            </Grid.Column>

            <Grid.Column >
              <ValueCard
                icon="leaf"
                title={"Building a Better Future"}
                text={"We believe in leaving this planet better than we found it. Our goal is to build products that are a part of the change we want to see."}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider horizontal style={{ paddingTop: 15, paddingBottom: 30 }}>
          <Header as='h1' style={{ fontSize: "3rem" }} className="small-caps">
            Meet our team
        </Header>
        </Divider>
        {members.map((member) => (
          <ProfileCard
            key={member.email}
            {...member}
          />
        ))}
      </Container>
    </>
  );
};

export default withLayout(AboutPage);

export const pageQuery = graphql`
query query {
        team:allTeamJson {
        members:nodes {
          bio
          email
          name
          credentials
          jobTitle
          linkedIn
          skills
          avatar {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
          }
      }
    }
  }
}
}`;
