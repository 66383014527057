import * as React from "react";
import {
  Grid,
  Header,
  Icon,
  SemanticICONS
} from "semantic-ui-react";

interface ValueCardProps {
  icon?: SemanticICONS;
  title: string;
  text: string;
}


const ValueCard: React.SFC<ValueCardProps> = ({
  icon = "thumbs up outline",
  title,
  text
}) => {
  return (
    <Grid relaxed={true} padded={true} columns={1} textAlign="center">
      <Grid.Row>
        <Grid.Column>
          <Icon fitted={true} name={icon} size="huge" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as={"h2"} className="small-caps">{title}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {text}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ValueCard; 
